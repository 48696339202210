import React from 'react';
import SectionBanner from './SectionBanner';
import SectionAreasInterest from './SectionAreasInterest';
import SectionDragCarousel from './SectionDragCarousel';
import SectionPortfolioStories from './SectionPortfolioStories';
import SectionTotalInvestment from './SectionTotalInvestment';
import SectionVideo from './SectionVideo';

const Homepage = () => {
  return (
    <div
      className="bg-brand-black text-brand-white min-h-screen"
      style={{ overflow: 'hidden' }}
    >
      <SectionBanner />
      <SectionTotalInvestment />
      <SectionPortfolioStories />
      <SectionVideo />
      <SectionAreasInterest />
      <SectionDragCarousel />
    </div>
  );
};

export default Homepage;
