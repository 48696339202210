import React from 'react';
import CustomContainer from '../../CustomContainer';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';

const SectionVideo = () => {
  const settings = {
    arrows: true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div
      className="h-[40vh] sm:h-[50vh] lg:h-[70vh] xl:h-[85vh] mt-20"
      style={{
        background: 'linear-gradient(to bottom, #192028 10%, #fff 100%)',
      }}
    >
      <CustomContainer>
        <div className="flex justify-center items-center h-[200px] sm:h-[350px] lg:h-[370px] xl:h-[50vh] xl:pt-14">
          <div className="w-[100%] lg:w-[70%] xl:w-[70%] h-[200px] sm:h-[350px] lg:h-[370px] xl:h-[50vh]">
            <Slider {...settings}>
              <div className="sm:px-14 xl:px-10 h-[200px] sm:h-[350px] lg:h-[370px] xl:h-[50vh] w-full z-[5]">
                <ReactPlayer
                  url="https://www.youtube.com/watch?v=hLYIUwqW9Vk"
                  width="100%"
                  height="100%"
                  style={{
                    boxShadow: '3px 10px 15px -3px rgb(0,0,0,0.3)',
                  }}
                />
              </div>
              <div className="sm:px-14 xl:px-10 h-[200px] sm:h-[350px] lg:h-[370px] xl:h-[50vh] w-full z-[5]">
                <ReactPlayer
                  url="https://youtu.be/CsRdFfGWLJw"
                  width="100%"
                  height="100%"
                  style={{
                    boxShadow: '3px 10px 15px -3px rgb(0,0,0,0.3)',
                  }}
                />
              </div>
            </Slider>
          </div>
        </div>
      </CustomContainer>
    </div>
  );
};

export default SectionVideo;
