import * as React from 'react';
import PropTypes from 'prop-types';
import CustomContainer from '../../../CustomContainer';

const RingContainer = ({ className, ringColor, title, description }) => (
  <div
    className={`flex flex-col items-center w-[170px] sm:w-[270px] ${className}`}
  >
    <div
      className="flex justify-center items-center h-[150px] sm:h-[250px] w-[150px] sm:w-[250px]"
      style={{
        border: `2px solid ${ringColor}`,
        borderRadius: '50%',
        filter: 'drop-shadow(0px 0px 10px rgba(244, 242, 234, 0.4))',
      }}
    >
      <p className="text-md sm:text-lg">{title}</p>
    </div>
    <div>
      <p className="mt-3 sm:mt-5 text-xs sm:text-sm w-full text-center">
        {description}
      </p>
      <p className="font-extralight text-xs sm:text-sm opacity-40">
        *data as of May 2022.
      </p>
    </div>
  </div>
);

const SectionTotalInvestment = () => {
  return (
    <CustomContainer className="h-full sm:h-[70vh] lg:h-screen flex flex-col justify-center">
      <p className="font-light leading-loose mb-5 sm:mb-14 text-sm sm:text-md sm:w-[90%] lg:w-[80%]">
        <b className="font-bold">We focus on investments that help cultivate</b>{' '}
        impactful early to growth stage companies in Indonesia's growing market.
      </p>
      <div className="flex flex-col sm:flex-row items-end sm:items-start justify-end sm:justify-center lg:justify-end w-full pr-5 sm:pr-0">
        <RingContainer
          title="20"
          description="Total companies invested"
          ringColor="#fff"
        />
        <RingContainer
          title="$438 M"
          description="Total companies' value"
          ringColor="#299146"
          className="mt-5 sm:mt-0 sm:ml-14"
        />
      </div>
    </CustomContainer>
  );
};

RingContainer.propTypes = {
  className: PropTypes.string,
  ringColor: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default SectionTotalInvestment;
