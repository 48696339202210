import * as React from 'react';
import RightArrow from 'images/icon/right-arrow-icon.svg';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import CustomContainer from '../../../CustomContainer';
import Slider from 'react-slick';
import { useMediaQuery, useWindowWidth } from '../../../hooks/useResponsive';
import DrSoapLogo from 'images/portfolioStories/dr-soap-stories-logo.png';
import AmazaraLogo from 'images/portfolioStories/amazara-stories-logo.png';
import R66Logo from 'images/portfolioStories/r66-stories-logo.png';
import HangryLogo from 'images/portfolioStories/hangry-stories-logo.png';
import QoalaLogo from 'images/portfolioStories/qoala-stories-logo.png';
import UssNetworksLogo from 'images/portfolioStories/uss-networks-stories-logo.png';
import SequioaHillsLogo from 'images/portfolioStories/sequioa-hills-stories-logo.png';
import HangryFounder from 'images/portfolioStories/hangry-stories-founder.png';
import AmazaraFounder from 'images/portfolioStories/amazara-stories-founder.png';
import DrSoapFounder from 'images/portfolioStories/dr-soap-stories-founder.png';
import R66Founder from 'images/portfolioStories/r66-stories-founder.png';
import QoalaFounder from 'images/portfolioStories/qoala-stories-founder.jpg';
import UssNetworksFounder from 'images/portfolioStories/uss-networks-stories-founder.png';
import SequioaHillsFounder from 'images/portfolioStories/sequioa-hills-stories-founder.png';
import HangryDisplay from 'images/portfolioStories/hangry-stories-display.jpeg';
import DrSoapDisplay from 'images/portfolioStories/dr-soap-stories-display.jpg';
import AmazaraDisplay from 'images/portfolioStories/amazara-stories-display.jpg';
import R66Display from 'images/portfolioStories/r66-stories-display.png';
import QoalaDisplay from 'images/portfolioStories/qoala-stories-display.png';
import UssNetworksDisplay from 'images/portfolioStories/uss-networks-stories-display.png';
import SequioaHillsDisplay from 'images/portfolioStories/sequioa-hills-stories-display.jpg';

const PanelSectionContent = ({
  image,
  logoCircle,
  logoRectangle,
  founder,
  description,
  slug,
}) => (
  <div className="h-screen flex justify-center items-center pr-5 sm:px-14 pb-[30px] sm:pb-0">
    <div className="flex flex-col lg:flex-row justify-center items-center w-full">
      <div className="w-[100%] lg:w-[50%] xl:w-[45%] h-full flex-col items-center justify-center">
        <img
          className="rounded-full ml-14 h-[90px] sm:h-[110px] w-[90px] sm:w-[110px] object-cover"
          src={logoCircle}
          alt="founder"
        />
        <img
          className="mt-3 h-[50px] sm:h-[60px] w-[170px] sm:w-[200px] object-contain"
          src={logoRectangle}
          alt="company"
        />
        <div className="text-left pt-8 lg:pr-8">
          <div className="text-base sm:text-md font-normal leading-tight">
            {founder}
          </div>
          <div className="text-[14px] sm:text-[18px] pt-5 pb-9 font-light leading-relaxed">
            {description}
          </div>
          <div
            className="flex flex-row items-center hover:opacity-50 hover:underline duration-500 cursor-pointer"
            onClick={() => {
              navigate(`/portfolio/${slug}`);
            }}
          >
            <p className="text-[14px] sm:text-base pr-2 font-light">
              Portfolio Profile
            </p>
            <img src={RightArrow} alt="right-arrow" />
          </div>
        </div>
      </div>
      <div className="w-[100%] lg:w-[50%] xl:w-[55%] h-full hidden sm:block sm:mt-9 lg:mt-0">
        <img
          className="object-cover h-[330px] xl:h-[380px] w-full hidden sm:block"
          alt="cover-rectangle"
          src={image}
        />
      </div>
    </div>
  </div>
);

const SectionPortfolioStories = () => {
  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();

  const settings = {
    dots: windowWidth < mediaQuery.sm && true,
    arrows: windowWidth >= mediaQuery.sm && true,
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <CustomContainer>
      <Slider {...settings}>
        <PanelSectionContent
          logoCircle={DrSoapFounder}
          logoRectangle={DrSoapLogo}
          founder="Eunike Selomith & Joycellynne Stefanie"
          description="dr soap® brings safe and eco-friendly alternatives into the personal care and household need market. The multi-channel D2C brand offers high-quality antiseptics and primary products that are formulated using plant-based and premium laboratory-made ingredients through offline and online sales."
          slug="dr-soap"
          image={DrSoapDisplay}
        />
        <PanelSectionContent
          logoCircle={AmazaraFounder}
          logoRectangle={AmazaraLogo}
          founder="Uma Hapsari"
          description="Amazara is one of the most popular D2C shoe companies. Seamlessly combining comfortability, style, and affordability, their shoes are among the best-selling on various e-commerce platforms."
          slug="amazara"
          image={AmazaraDisplay}
        />
        <PanelSectionContent
          logoCircle={R66Founder}
          logoRectangle={R66Logo}
          founder="Helmy Yahya"
          description="Founded by the prominent entertainment and media figure Helmy Yahya, R66 Media is a multi-channel network company that serves as an incubator for content creators. It provides assistance for content creators in such areas as production, funding, intellectual property, and audience development."
          slug="r66-media"
          image={R66Display}
        />
        <PanelSectionContent
          logoCircle={HangryFounder}
          logoRectangle={HangryLogo}
          founder="Abraham Viktor, Robin Tan, and Andreas Resha"
          description="Since its founding in 2019, Hangry has become the fastest growing multi-brand virtual restaurant in Indonesia. So far, Hangry has built several successful brands such as Moon Chicken, Ayam Koplo, Kopi Dari Pada, and San Gyu and continues to offer gourmet dishes for daily consumption."
          slug="hangry"
          image={HangryDisplay}
        />
        <PanelSectionContent
          logoCircle={QoalaFounder}
          logoRectangle={QoalaLogo}
          founder="Tommy Martin & Harshet Lunani"
          description="Qoala is redefining insurance by providing more affordable and accessible insurance for everyone. Through the strategy of providing equal value to all stakeholders comprising insurance partners, customers, and regulators, the omni-channel insurance provider has established a strong presence in Indonesia, Malaysia, Vietnam, and Thailand."
          slug="qoala"
          image={QoalaDisplay}
        />
        <PanelSectionContent
          logoCircle={UssNetworksFounder}
          logoRectangle={UssNetworksLogo}
          founder="Jeffry Jouw & Sayed Muhammad"
          description="Over the years, USS Networks has been growing into a contemporary lifestyle content network that houses conversations around youth culture. Founded by sub-culture personalities Jeffry Jouw (Jejouw) and Sayed Muhammad, the content network has grown a series of highly successful lifestyle brands."
          slug="uss-networks"
          image={UssNetworksDisplay}
        />
        <PanelSectionContent
          logoCircle={SequioaHillsFounder}
          logoRectangle={SequioaHillsLogo}
          founder="Triniti Land"
          description="Sequoia Hills introduces a new sustainable real estate concept of a breathing city. In a strategic partnership with Triniti Land, the billion-dollar project is developing a unique residential concept that highlights a natural landscape comprising lake, river, and forest on 95.5 hectares of land in Sentul."
          slug="sequioa-hills"
          image={SequioaHillsDisplay}
        />
      </Slider>
    </CustomContainer>
  );
};

PanelSectionContent.propTypes = {
  image: PropTypes.string,
  logoCircle: PropTypes.string,
  logoRectangle: PropTypes.string,
  founder: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
};

export default SectionPortfolioStories;
