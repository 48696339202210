import React, { useState } from 'react';
import Retail from 'images/areasOfInterest/retail.jpg';
import Media from 'images/areasOfInterest/media.jpg';
import RealEstate from 'images/areasOfInterest/real-estate.jpg';
import Web3 from 'images/areasOfInterest/web3.jpg';
import HealthTech from 'images/areasOfInterest/health-tech.png';
import { useWindowWidth, useMediaQuery } from '../../../hooks/useResponsive';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import CustomContainer from '../../../CustomContainer';

const listOfAreas = [
  {
    title: 'Omni Channel Retail Brands',
    image: Retail,
    subTitle: 'Omni Channel Retail Brands',
  },
  {
    title: 'Media Commerce and Entertainment',
    image: Media,
    subTitle: 'Media Commerce and Entertainment',
  },
  {
    title: 'Sustainable Real Estate Development',
    image: RealEstate,
    subTitle: 'Sustainable Real Estate Development',
  },
  {
    title: 'Web3 Ecosystem',
    image: Web3,
    subTitle: 'Web3 Ecosystem',
  },
  {
    title: 'Health Tech',
    image: HealthTech,
    subTitle: 'Health Tech',
  },
];

const CardClickAble = ({ title, onHoverIn, onHoverOut, subTitle, isHover }) => {
  return (
    <div
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
      className="w-full flex justify-center transition-all"
    >
      <div className="w-full px-5 md:px-10 lg:px-26 relative py-4 sm:py-8">
        <Link to="/portfolio">
          <p
            className={`${
              isHover ? 'font-[600]' : 'font-light'
            } relative text-base  lg:text-[32px] transition-all  cursor-pointer text-center`}
          >
            {title}
          </p>
          <p
            style={{ transitionDuration: '0.8s' }}
            className={`${
              isHover ? 'opacity-40 translate-x-0' : 'opacity-0 translate-x-16'
            } absolute text-center w-full sm:text-md lg:text-[40px] sm:top-3 lg:top-1 left-[-15px] font-[600] transition-all cursor-pointer`}
          >
            {subTitle}
          </p>
        </Link>
      </div>
    </div>
  );
};

const SectionAreasInterest = () => {
  const [activeBackground, setActiveBackground] = useState();
  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();

  const handleHoverIn = (image) => {
    if (windowWidth >= mediaQuery.md) {
      setActiveBackground(image);
    }
  };

  return (
    <div
      className={`h-screen relative w-full py-20 before:content-[""] 
      before:absolute before:left-0 before:top-0 before:bg-cust-green-400 before:w-full before:h-full before:opacity-80 overflow-hidden`}
      style={{
        transition: 'all 0.8s ease-out',
        backgroundImage: activeBackground
          ? `url(${activeBackground})`
          : 'linear-gradient(180deg, #DCDCDC, #000)',
        backgroundBlendMode: 'overlay',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <CustomContainer className="flex flex-col justify-center items-center h-full">
        <p className="text-[24px] lg:text-lg font-medium mb-5 z-10">
          Our Areas of Interest
        </p>
        {listOfAreas.map((item, index) => {
          return (
            <CardClickAble
              onHoverIn={() => handleHoverIn(item.image)}
              onHoverOut={() => setActiveBackground()}
              key={index}
              title={item.title}
              subTitle={item.subTitle}
              isHover={item.image === activeBackground}
            />
          );
        })}
      </CustomContainer>
    </div>
  );
};

CardClickAble.propTypes = {
  title: PropTypes.string,
  onHoverIn: PropTypes.any,
  onHoverOut: PropTypes.any,
  subTitle: PropTypes.string,
  isHover: PropTypes.bool,
};

export default SectionAreasInterest;
