import * as React from 'react';
import Pages from '../components/Pages';
import Homepage from '../components/pages/homepage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const IndexPage = () => {
  return (
    <Pages>
      <Homepage />
    </Pages>
  );
};

export default IndexPage;
