import React, { useEffect, useState } from 'react';
import { useSpring, animated, useSprings } from 'react-spring';
import ArrowIcon from 'images/icon/arrow-down.svg';
import { useWindowWidth, useMediaQuery } from '../../../hooks/useResponsive';

const innerAnimationText = {
  text1: 'We Are SALT Ventures',
  text2: 'We Are Partners of Extra-Mile Walkers',
};

const SectionBanner = () => {
  const windowWidth = useWindowWidth();
  const mediaQuery = useMediaQuery();
  const [isMasking, setIsMasking] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const animationScroll = useSpring({
    from: { opacity: 0, transform: `translateY(10px)` },
    to: {
      opacity: isHovered ? 1 : 0,
      transform: isHovered ? `translateY(0px)` : `translateY(10px)`,
    },
  });

  const text1 = innerAnimationText.text1.split('');
  const text2 = innerAnimationText.text2.split('');

  const springs2 = useSprings(
    text2.length,
    text2.map((text, index) => ({
      config: { duration: 200 },
      from: {
        opacity: 0,
        transform: `translateY(20px)`,
        display: 'inline-block',
      },
      to: {
        opacity: isHovered ? 1 : 0,
        transform: isHovered ? `translateY(0px)` : `translateY(20px)`,
      },
      delay: 10 * index,
    })),
  );

  const springs = useSprings(
    text1.length,
    text1.map((text, index) => ({
      config: { duration: 200 },
      from: {
        opacity: 1,
        transform: `translateY(0px)`,
        display: 'inline-block',
      },
      to: {
        opacity: isHovered ? 0 : 1,
        transform: isHovered ? `translateY(-20px)` : `translateY(0px)`,
      },
      delay: 10 * index + 10,
    })),
  );

  const handleMouseEnter = () => {
    if (windowWidth >= mediaQuery.sm) {
      setIsHovered(true);
    }
  };

  useEffect(() => {
    if (windowWidth <= mediaQuery.sm) {
      setTimeout(() => {
        setIsHovered(!isHovered);
      }, 3000);
    }
  }, [windowWidth, mediaQuery]);

  return (
    <div className="relative h-screen w-screen pt-20">
      <div
        className={`${
          isMasking ? 'z-0' : 'z-20'
        } absolute h-full w-full left-0 flex justify-center top-0 transition-all delay-[2000]`}
      >
        <video className="masking" autoPlay playsInline muted loop>
          <source src="https://finfolk.ap-south-1.linodeobjects.com/salt-banner-video-portrait.mp4" />
        </video>
        <div className="absolute top-0 text-white w-full h-full flex justify-center items-center flex-col">
          <div
            className={`${
              isMasking ? 'blur-none opacity-100' : 'blur-2xl opacity-0'
            }  w-full transition-all flex justify-center`}
            style={{ transition: '2s' }}
          >
            <p
              onMouseEnter={handleMouseEnter}
              onMouseLeave={() => setIsHovered(false)}
              className={`text-md sm:text-lg font-thin cursor-pointer text-center w-full relative h-32`}
              style={{
                transition: 'all 0.3s ease-out',
              }}
            >
              <div className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-full px-5">
                {springs2.map((prop, index) => {
                  if (index === 18 && windowWidth <= mediaQuery.sm) {
                    return <br />;
                  } else {
                    return (
                      <animated.span
                        key={index}
                        style={prop}
                        className={index >= 19 ? 'font-medium' : 'font-thin'}
                      >
                        {text2[index] === ' ' ? <>&nbsp;</> : text2[index]}
                      </animated.span>
                    );
                  }
                })}
              </div>
              <div className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 w-full">
                {springs.map((prop, index) => {
                  return (
                    <animated.span
                      key={index}
                      style={prop}
                      className={
                        index >= 7 && index <= 10 ? 'font-medium' : 'font-thin'
                      }
                    >
                      {text1[index] === ' ' ? <>&nbsp;</> : text1[index]}
                    </animated.span>
                  );
                })}
              </div>
            </p>
          </div>
          <div
            className={`absolute bottom-[4%] ${
              isMasking ? 'blur-none opacity-100' : 'blur-2xl opacity-0'
            } `}
            style={{ transition: '2s' }}
          >
            <animated.div
              style={animationScroll}
              className="font-thin flex flex-col items-center"
            >
              <p>Scroll to Explore</p>
              <img src={ArrowIcon} alt="icon" className="w-5 mt-3" />
            </animated.div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionBanner;
